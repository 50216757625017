import { get, post, put, Delete } from "../axios";

// 软件或工具列表-软件或工具分类（用于软件或工具列表查询和新增编辑）
export const infoSoftwareClassify = (params) =>
  get("/web/info/software/classify/list", params);

// 软件或工具列表-列表
export const infoSoftwareList = (params) =>
  get("/web/info/software/list", params);

// 软件或工具列表-新增
export const infoSoftwareAdd = (params) =>
  post("/web/info/software/add", params);

// 软件或工具列表-编辑
export const infoSoftwareUpdate = (params) =>
  put("/web/info/software/update", params);

// 软件或工具列表-删除
export const infoSoftwareDelete = (id) =>
  Delete("/web/info/software/delete/" + id, "");

// 软件或工具列表-详情
export const infoSoftwareView = (id) =>
  get("/web/info/software/view/" + id, "");

// 软件或工具列表-首页推荐-状态编辑
export const infoSoftwareHomeUpdate = (params) =>
  put("/web/info/software/home/update", params);
