import { convertEnum } from "../enumUtil";

/**
 * 通用开关 启用 关闭
 */
export const EDisplayStatus = {
  /** 开启 */
  open: 1,

  /** 关闭 */
  close: 2,
};

export const [MDisplayStatus, ODisplayStatus] = convertEnum([
  [EDisplayStatus.open, "开启"],
  [EDisplayStatus.close, "关闭"],
]);

/**
 * 订单状态
 */
export const EApplicationStatus = {
  /** 未处理 */
  Unprocessed: 1,

  /** 已处理 */
  Processed: 2,
};
export const [MOrderStatus, OOrderStatus] = convertEnum([
  [EApplicationStatus.Unprocessed, "待处理"],
  [EApplicationStatus.Processed, "已处理"],
]);

/**
 * 操作日志类型
 */
export const EOperationLog = {
  /** 新增 */
  add: 1,

  /** 修改 */
  modify: 2,
  /** 删除 */
  del: 3,
  /** 导出 */
  export: 4,
  /** 导入 */
  Import: 5,
};
export const [MOperationLog, OOperationLog] = convertEnum([
  [EOperationLog.add, "新增"],
  [EOperationLog.modify, "修改"],
  [EOperationLog.del, "删除"],
  [EOperationLog.export, "导出"],
  [EOperationLog.Import, "导入"],
]);

export const ETypeInfo = {
  /** 富文本 */
  one: 1,
  /** 文件 */
  two: 2,
  /** 压缩包 */
  three: 3,
};
export const [MTypeInfo, OTypeInfo] = convertEnum([
  [ETypeInfo.one, "富文本"],
  [ETypeInfo.two, "文件"],
  [ETypeInfo.three, "压缩包"],
]);

export const EMemberExamine = {
  /** 待审核 */
  PendingReview: 1,
  /** 审核通过 */
  adopt: 2,
  /** 审核拒绝 */
  refuse: 3,
};
export const [MMemberExamine, OMemberExamine] = convertEnum([
  [EMemberExamine.PendingReview, "待审核"],
  [EMemberExamine.adopt, "审核通过"],
  [EMemberExamine.refuse, "审核拒绝"],
]);
